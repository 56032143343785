.museum {
  background-image: url("./icons/museum.png");
}

.mask {
  background-image: url("./icons/mask.png");
}
.mountain {
  background-image: url("./icons/mountain.png");
}
.camera {
  background-image: url("./icons/camera.png");
}

label {
	font-size: 30px;
}

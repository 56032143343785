input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  color: #137dc5;
}

.subTitles {
	font-size: 22px;
}

input[type="range"]::-webkit-slider-thumb {
  color: #137dc5;
}

.buttons {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.button {
  margin-top: 1rem;
  width: 3rem;
  height: 3rem;
  border-color: #137dc5;
  border-radius: 5px;
  box-shadow: 0px 0px 3px #137dc5;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: content-box;
  padding: 5px;
}

.walk {
  background-image: url("./icons/walk.png");
}

.bike {
  background-image: url("./icons/bike.png");
}
.train {
  background-image: url("./icons/train.png");
}

.car {
  background-image: url("./icons/car.png");
}

.radio {
  opacity: 0;
}

.next {
  color: white;
  background: #137dc5;
  margin: 4rem auto 0 auto;
  width: 5rem;
  height: 3rem;
  border: solid #137dc5 2px;
  border-radius: 5px;
  box-shadow: 0px 0px 2px #137dc5;
}

.goToBack {
  color: #137dc5;
  margin: 4rem auto 0 auto;
  width: 5rem;
  height: 3rem;
  border-radius: 5px;
  text-decoration: underline;
  text-transform: capitalize;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.generalInfoWrapper {
	padding: 6rem 4rem;
	font-size: 16pt;
	color: #137dc5;
}
